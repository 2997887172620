/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap");

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;800;900&display=swap");

/* Color Change */
@import "~@angular/material/theming";
@include mat-core();
$mat-blue: (
    50: #e3f2fd,
    100: #bbdefb,
    200: var(--ion-color-blue-light),
    300: #64b5f6,
    400: #42a5f5,
    500: #2196f3,
    600: #1e88e5,
    700: #1976d2,
    800: #1565c0,
    900: #0d47a1,
    A100: #82b1ff,
    A200: #448aff,
    A400: #2979ff,
    A700: #2962ff,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: #fff,
        300: $dark-primary-text,
        400: $dark-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    ),
);

$primary: mat-palette($mat-blue, 200);
$accent: mat-palette($mat-orange, 200);
$theme: mat-light-theme($primary, $accent);
@include angular-material-theme($theme);

ion-menu {
    --side-max-width: 280px;
    /* @media (max-width: 1660px) {
        --side-max-width: 280px;
    } */
}

* {
    --ion-font-family: "Noto Sans KR", sans-serif;
}

ion-label {
    font-size: 14px;
}

ion-input,
ion-select {
    --background: var(--ion-white) !important;
    background: var(--ion-white) !important;
    border: 1px solid rgb(203, 213, 225);
    --padding-start: 15px !important;
    --padding-end: 20px !important;
    border-radius: 6px;
    font-size: 16px;
    width: 100%;
    color: rgba(#1f314a, 0.8) !important;
    outline: none;
    margin: 8px 0;
    --padding-top: 7px !important;
    --padding-bottom: 7px !important;

    &:focus {
        border: 1px solid var(--ion-color-blue-light);
        --border: 1px solid var(--ion-color-blue-light);
    }

    &.error,
    &.ion-dirty {
        /* border-color: var(--ion-color-red) !important; */
        /* border-left: 5px solid var(--ion-color-red) !important; */
    }
    &.ion-valid {
        /* border-color: var(--ion-color-success) !important; */
        /* border-left: 5px solid var(--ion-color-success) !important; */
    }
}

mat-form-field {
    width: 100%;
    margin-top: 8px;

    .mat-form-field-flex {
        border: 0;
        border: 1px solid rgb(203, 213, 225);
        padding: 11px !important;
        color: var(--ion-font-color);
        border-radius: 6px !important;
        background: var(--ion-white) !important;
        .mat-form-field-infix {
            border: 0;
            padding: 0;
        }
    }

    .mat-icon-button {
        width: 20px !important;
        height: 20px !important;
    }
    .mat-form-field-wrapper {
        padding: 0;
    }

    .mat-form-field-underline {
        display: none;
    }

    /* .mat-form-field-prefix{
        .mat-form-field-infix {
            border: 0 !important;
        }
    } */

    .mat-form-field-underline {
        height: 0 !important;
    }
    .mat-form-field-subscript-wrapper {
        display: none !important;
    }

    &.mat-focused {
        .mat-form-field-ripple {
            opacity: 0 !important;
        }
    }
    &.mat-form-field-invalid {
        .mat-form-field-ripple {
            opacity: 0 !important;
        }
    }
}

ion-textarea {
    height: 163px;
    /* background: rgba($color: #1f314a, $alpha: 0.1) !important; */
    background: var(--ion-white) !important;
    border: 0;
    display: block;
    --padding-start: 15px !important;
    --padding-end: 20px !important;
    border-radius: 12px;
    font-size: 16px;
    width: 100%;
    color: rgba(#1f314a, 0.8) !important;
    border: 1px solid rgb(203, 213, 225);
    outline: none;
    margin: 8px 0;
    .textarea-wrapper,
    .native-textarea {
        height: 100%;
    }
}

ion-button {
    height: 40px;
    @media (max-width: 767px) {
        font-size: 12px;
    }
    &.border-button {
        --border-color: var(--ion-border-color);
        --border-style: solid;
        --border-width: 1px;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    color: var(--ion-color-blue-magenta);
}

h1 {
    font-size: 30px;
    color: var(--ion-color-dark-base);
    font-weight: 600;
    margin: 0;
}

.noPlane {
    .alert-wrapper {
        width: 400px;
        max-width: none;
    }

    .alert-head {
        padding-top: 10px;
    }
    .alert-message {
        color: var(--ion-color-blue-light);
        text-align: center;
        line-height: 1.4;
    }

    .alert-button-group {
        justify-content: space-between;
        max-width: 260px;
        margin: 0 auto 20px;
        button {
            width: 48%;
            margin: 0;
            border-radius: 23px;
            background: var(--ion-color-dark-base);
            text-transform: none;
            color: #fff;
            &:last-child {
                background: var(--ion-color-red);
            }
            .alert-button-inner {
                justify-content: center;
            }
        }
    }
}

.title-section {
    margin-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid #d2d2d2;

    h1 {
        font-size: 24px;
    }

    ion-select {
        margin: 0;
        height: 40px;
        margin-right: 15px;
    }

    ion-searchbar {
        padding-top: 0;
        padding-bottom: 0;
        height: 43px;
    }
    ion-button {
        margin: 0;
        width: auto;
        height: 40px;
        text-transform: none;
        font-size: 12px;
        font-weight: 600;
        --border-radius: 999px;
        --padding-start: 20px;
        --padding-end: 20px;
    }
    ion-icon {
        font-size: 40px;
        margin-left: 18px;
    }
}
.add-template {
    --height: 250px;
}
.task-description {
    --width: 1200px;
}
.create-documents {
    --width: 540px;
    --height: 410px;
}

/* Secondery Menu */

.secondary-menu {
    background: var(--ion-white);
    width: 210px;
    padding-right: 10px;
    padding-top: 0;
    min-height: calc(100vh - 85px);
    border-right: 1px solid #e2e8f0;
    padding-left: 12px;
    padding-right: 12px;

    ul {
        padding: 0;
        list-style: none;
        > li {
            margin-bottom: 30px;
        }
        li {
            a {
                display: block;
                line-height: 40px;
                padding-left: 16px;
                padding-right: 16px;
                color: var(--ion-color-blue-light);
                text-decoration: none;
                font-size: 13px;
                ion-icon {
                    font-size: 21px;
                    transition: 0.5s ease transform;
                }
                &.secondary-menu-title {
                    color: var(--ion-color-blue-light);
                    font-weight: bold;
                    text-transform: uppercase;
                }
            }

            &.open_menu {
                ion-icon {
                    transform: rotate(180deg);
                }
                ul {
                    &.secondMenu {
                        height: 120px;
                    }
                }
            }
            &:not(.open_menu) {
                ul {
                    &.first_menu {
                        height: 0 !important;
                    }
                }
            }

            > ul {
                list-style: none;
                padding-left: 0px;
                transition: 0.5s ease all;

                &.first_menu {
                    overflow: hidden;
                }

                &.secondMenu {
                    height: 0;
                    overflow: hidden;
                }
                li {
                    position: relative;
                    margin-bottom: 4px;
                    a {
                        font-size: 13px;
                        border-radius: 6px;
                        color: rgb(30, 41, 59, 0.8);
                        font-weight: 500;
                        &:hover,
                        &.active {
                            background: rgba(30, 41, 59, 0.05);
                            color: rgb(30, 41, 59, 1);
                        }
                    }
                }
            }
        }
    }
}

.opacity-0 {
    /* opacity: 0;
    position: absolute;
    visibility: hidden;
    left: 0;
    top: 0; */
    opacity: 0;
}

.title-header-section {
    background: var(--ion-white);
    padding: 15px 30px;
    border-bottom: 1px solid rgb(226, 232, 240);

    @media (max-width: 959px) {
        padding-left: 24px;
        padding-right: 24px;
    }

    @media (max-width: 767px) {
        padding: 24px;
    }

    h1 {
        font-size: 24px;
        font-weight: 800;
        margin: 0;
        @media (max-width: 767px) {
            margin-bottom: 20px;
        }
    }
}

.title-header-section-small {
    background: var(--ion-white);
    padding: 15px 30px;
    border-bottom: 1px solid rgb(226, 232, 240);

    @media (max-width: 767px) {
        padding-left: 24px;
        padding-right: 24px;
    }

    h1 {
        font-weight: 800;
        color: #1e293b;
        font-size: 32px;
    }
    h3 {
        font-weight: 800;
        font-size: 26px;
        color: #1e293b;
        margin: 0;
        ion-icon {
            margin-left: 10px;
        }
        @media (max-width: 767px) {
            font-size: 22px;
        }
    }
    p {
        margin: 0;
        font-size: 14px;
    }
}
.plr-30 {
    padding-left: 30px;
    padding-right: 30px;
}

.no-ripple {
    --ripple-color: transparent;
}

ng-scrollbar {
    scrollbar-y {
        right: -16px !important;
    }
    .ng-scroll-content {
        margin: 0;
        padding-top: 20px;
        @media (max-width: 767px) {
            padding-top: 5px;
        }
    }
    .ng-scrollbar-thumb {
        border-radius: 50px;
    }
}

.secondery_menu_big {
    width: 382px;
    background: var(--ion-white);
    left: 0;
    position: fixed;
    height: calc(100vh - 66px);
    top: 66px;
    border-right: 1px solid #e2e8f0;
    transition: 0.5s ease all;

    @media (max-width: 1460px) {
        height: calc(100vh - 57px);
        top: 57px;
        left: -100%;
        z-index: 99;
        &.active {
            opacity: 1;
            visibility: visible;
            left: 0;
        }
    }
    @media (max-width: 767px) {
        width: 100%;
        height: calc(100vh - 56px);
        top: 56px;
    }
    .close {
        display: none;
        width: 40px;
        height: 40px;
        --border-radius: 50%;
        margin: 0;
        --background: none;
        --background-hover: var(--ion-color-font-light);
        --box-shadow: none;
        color: var(--ion-font-color);
        --padding-start: 0;
        --padding-end: 0;
        margin-right: 10px;
        ion-icon {
            font-size: 26px;
        }
        @media (max-width: 1460px) {
            display: block;
            opacity: 1;
            visibility: visible;
        }
        @media (max-width: 767px) {
            margin-right: 0;
        }
    }

    .menu-title-secondery-menu {
        padding: 30px;
        h3 {
            color: var(--ion-font-color);
            font-weight: 800;
        }
    }
    ul {
        padding: 0;
        margin: 0;
        li {
            padding: 20px 30px;
            border-top: 1px solid rgb(226, 232, 240);
            cursor: pointer;

            &:hover {
                background: rgba(241, 245, 249, 1);
            }
            &.active {
                background: rgb(238, 242, 255);
                .icon {
                    color: rgb(79, 70, 229);
                }
                .menu-content {
                    h5 {
                        color: rgb(79, 70, 229);
                    }
                }
            }

            &:last-child {
                border-bottom: 1px solid rgb(226, 232, 240);
            }
            .icon {
                width: 30px;
                font-size: 21px;
            }
            .menu-content {
                width: calc(100% - 30px);
                h5 {
                    font-size: 14px;
                    color: var(--ion-font-color);
                    line-height: 28px;
                    margin: 0;
                    font-weight: 600;
                }
                p {
                    color: rgb(100, 116, 139);
                    font-size: 14px;
                    margin: 3px 0 0;
                }
            }
        }
    }
    ng-scrollbar scrollbar-y {
        right: -4px !important;
    }
    .ng-scroll-viewport {
        padding-right: 0 !important;
    }
}

.setting-title {
    margin-bottom: 30px;
    .menu-icon {
        display: none;
        width: 40px;
        height: 40px;
        --border-radius: 50%;
        margin: 0;
        --background: none;
        --background-hover: var(--ion-color-font-light);
        --box-shadow: none;
        color: var(--ion-font-color);
        --padding-start: 0;
        --padding-end: 0;
        margin-right: 10px;
        margin-left: -15px;
        ion-icon {
            font-size: 26px;
        }
        @media (max-width: 1460px) {
            display: block;
            opacity: 1;
            visibility: visible;
        }
    }
    h4 {
        font-size: 24px;
        color: var(--ion-font-color);
        font-weight: bold;
        margin: 0;
    }
    p {
        margin: 0;
        color: var(--ion-font-color);
        font-size: 14px;
        font-weight: 500;
    }
}

.padding {
    padding: 15px 30px;
    margin-top: 30px;
}

.right-side-popup {
    width: 400px;
    transform: translateX(400px);
    overflow: auto;
    position: fixed;
    right: 0;
    top: 66px;
    bottom: 0;
    background: var(--ion-white);
    border-left: 1px solid var(--ion-border-color);
    transition: 0.4s ease all;
    z-index: 9;

    @media (max-width: 767px) {
        width: 100%;
        transform: translateX(100%);
        top: 56px;
    }

    &.right-extra {
        right: 17px;
    }

    &.big-popup {
        width: 512px;
        transform: translateX(512px);
        @media (max-width: 1460px) {
            width: 460px;
            transform: translateX(460px);
        }
        @media (max-width: 767px) {
            width: 100%;
            transform: translateX(100%);
        }

        .popup-title {
            padding-left: 32px;
            padding-right: 32px;
            @media (max-width: 767px) {
                padding-left: 24px;
                padding-right: 24px;
            }
        }
    }

    &.active {
        transform: translateX(0);
    }

    .close-button {
        font-size: 20px;
        color: var(--ion-font-color);
        --border-radius: 50%;
        width: 45px;
        height: 45px;
        --padding-start: 0;
        --padding-end: 0;
    }
    .popup-title {
        /* background: var(--ion-color-blue-light); */
        padding-left: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        /* 20 */
        margin-bottom: 0;
        padding-right: 20px;

        @media (max-width: 767px) {
            margin-bottom: 0;
            padding-bottom: 0;
        }

        h1 {
            font-size: 20px;
            font-weight: 800;
            margin: 0;
            color: var(--ion-font-color);
        }
    }
}

.button-group {
    border-top: 1px solid rgb(226, 232, 240);
    max-width: 900px;
    padding-top: 40px;
    ion-button {
        --border-radius: 23px;
        width: 120px;
        font-weight: bold;
    }
}

.dashbord-title {
    padding-left: 30px;
    @media (max-width: 767px) {
        padding-left: 24px;
    }
    h1 {
        font-size: 24px;
        margin: 25px 0 0;
        font-weight: 900;
    }
}

.ion-footer-button {
    padding-bottom: 30px;
    ion-button {
        width: 160px;
        height: 38px;
        margin: 0 10px;
        text-transform: none;
    }
}

.mat-menu-panel {
    .mat-menu-content {
        ion-icon {
            font-size: 20px;
            vertical-align: middle;
            margin-top: -4px;
            margin-right: 7px;
        }
    }
}

@media (max-width: 1460px) {
    .overlay {
        background-color: rgba(0, 0, 0, 0.6) !important;
        opacity: 0;
        visibility: hidden;
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        &.active {
            opacity: 1;
            visibility: visible;
        }
    }
}

mat-tab-group {
    margin-bottom: 30px;
    .mat-tab-label {
        height: 40px;
        width: auto;
        min-width: auto;
        border-radius: 23px;
        &.mat-tab-label-active {
            background: rgba(51, 65, 85, 0.2);
            color: rgba(0, 0, 0, 1);
            opacity: 1;
        }
    }
    mat-ink-bar {
        display: none !important;
    }
    .mat-tab-body-wrapper {
        display: none;
    }
    .mat-tab-header {
        border: 0 !important;
    }
}

.mat-menu-panel {
    min-width: 150px !important;
}

bt-task-list {
    .padding {
        margin-top: 115px;
        padding-left: 0;
        padding-right: 0;
        @media (max-width: 767px) {
            padding-bottom: 50px;
        }
    }
    .title-header-section {
        position: fixed;
        top: 66px;
        left: 210px;
        z-index: 999;
        right: 0;
        @media (max-width: 1460px) {
            left: 0;
            z-index: 11;
        }
        @media (max-width: 767px) {
            flex-direction: column;
            position: absolute;
            top: 56px;
            h1 {
                font-size: 24px;
                width: 100%;
                margin-bottom: 4px;
            }
        }
    }
}

.edit-create-stage {
    --min-width: 400px;
}

.min-w-320 {
    min-width: 66rem !important;
}

.menu-icon-task {
    display: none;
    width: 40px !important;
    height: 40px !important;
    --border-radius: 50%;
    margin: 0;
    --background: none;
    --background-hover: var(--ion-color-font-light);
    --box-shadow: none;
    color: var(--ion-font-color);
    --padding-start: 0 !important;
    --padding-end: 0 !important;
    margin-right: 10px;
    margin-left: -8px !important;
    ion-icon {
        font-size: 26px;
        margin: 0 !important;
    }
    @media (max-width: 1460px) {
        display: block;
        opacity: 1;
        visibility: visible;
    }
}

@media (min-width: 767px) {
    body .w-md-auto {
        width: auto !important;
    }
}

.mobile-footer {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    background: var(--ion-white);
    box-shadow: 0px -1px 20px 0px rgb(0 0 0 / 22%);
    &:before {
        display: none;
    }
    ion-button {
        margin: 0;
        width: 50%;
        --border-radius: 0;
        --box-shadow: none;
        --background: var(--ion-menu-background);
        &:nth-child(2n) {
            /* --background: var(--ion-menu-background); */
            border-left: 1px solid #828282;
        }
    }
}

/* Tags  */

.tags {
    margin-bottom: 25px;
    h4 {
        font-size: 14px;
        margin-bottom: 1rem;
        color: var(--ion-color-dark-base);
    }
    mat-chip {
        padding-left: 20px;
        padding-right: 25px;
        justify-content: center;

        ion-icon {
            vertical-align: middle;
            margin-right: 10px;
            font-size: 18px;
        }
    }
}

/* Loading */

.loader,
.loader:before,
.loader:after {
    border-radius: 50%;
    width: 1.5em;
    height: 1.5em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
}
.loader {
    color: #ffffff;
    font-size: 10px;
    margin: 20px ​auto;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}
.loader:before,
.loader:after {
    content: "";
    position: absolute;
    top: 0;
}
.loader:before {
    left: -2.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}
.loader:after {
    left: 2.5em;
}
@-webkit-keyframes load7 {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
}
@keyframes load7 {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
}

.loading {
    position: fixed;
    width: 100%;
    z-index: 99;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0b2752;
    transition: 0.5s ease all;
    opacity: 0;
    visibility: hidden;
    &.active {
        opacity: 1;
        visibility: visible;
    }
    flex-direction: column;
    img {
        width: 70px;
    }
}

.received {
    border-color: var(--ion-color-completed) !important;
    color: var(--ion-color-completed) !important;
    opacity: 1 !important;
    --placeholder-opacity: 1;
    /* --background: var(--ion-color-completed); */
}
.pending {
    border-color: var(--ion-color-blue-magenta) !important;
    color: var(--ion-color-blue-magenta) !important;
    opacity: 1 !important;
    --placeholder-opacity: 1;
    /* --background: var(--ion-color-blue-magenta); */
}
.invoiced {
    /* --background: var(--ion-color-warning); */
    border-color: var(--ion-color-warning) !important;
    color: var(--ion-color-warning) !important;
    opacity: 1 !important;
    --placeholder-opacity: 1;
}

.project-inclusion {
    .mat-dialog-container {
        padding: 0;
        background: transparent;
    }
}

bt-inclusion-create-category {
    .padding {
        padding-left: 30px;
        padding-right: 30px;
    }
}

ion-content {
    --background: var(--ion-body-color);
}

.create-estimates {
    --width: calc(100vw - 280px);
    left: 280px;
    --height: 100vh;
    overflow: auto;
}

button {
    &.danger {
        color: var(--ion-color-red) !important;
    }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.ck-editor__editable_inline {
    height: 260px;
}

.font-weight-500 {
    font-weight: 500 !important;
}

@media print {
    bt-view-estimates-invoices .top-section,
    ion-menu,
    bt-common-header {
        display: none !important;
    }

    ion-content {
        --background: none !important;
    }
    #invoice-body {
        padding: 0 !important;
        box-shadow: none !important;
        width: none !important;
    }
    .invoice-footer .content-section p {
        font-size: 18px;
    }

    body {
        bt-inclusion-view {
            .button-group {
                display: none !important;
            }
            .preview_inclusion {
                width: 100%;
                box-shadow: none !important;
                padding: 0 !important;
                .w-30 {
                    min-height: 100vh;
                }
            }
        }
        .openNoteArrow,
        .title-section,
        .top-section-invoice {
            display: none !important;
        }
    }

    bt-task-list {
        .title-header-section {
            display: none !important;
        }
        .button-group {
            display: none !important;
        }
        .preview_inclusion {
            width: 100% !important;
        }
    }
    .list-body-section {
        padding-top: 0 !important;
    }
}

.invoice-header {
    .invoice-detail {
        .invoice-title {
            margin-bottom: 20px;
            h1 {
                color: var(--ion-color-dark-base);
                font-weight: 500;
                font-size: 50px;
                margin: 0;
                line-height: 1;
                text-transform: uppercase;
            }
            .invoice-number {
                line-height: 1;
                font-weight: 500;
                font-size: 30px;
                color: var(--ion-font-color);
            }
        }
        .invoice-date-section {
            margin-bottom: 4px;
            .invoice-date-title {
                color: rgb(100, 116, 139);
                font-weight: 500;
                font-size: 14px;
                text-transform: uppercase;
            }
            .invoice-date {
                color: var(--ion-font-color);
                font-weight: 500;
                font-size: 14px;
            }
            .prefix {
                /* width: 55px; */
                padding-left: 3px;
                padding-right: 3px;
                height: 30px;
                margin-right: 5px;
                padding-left: 2px;
                border: 1px solid #64748b;
                color: var(--ion-font-color);
                border-radius: 4px;
            }
        }
    }

    .compnay-detail-header {
        background: var(--ion-color-dark-base);
        transform: translateX(64px);
        padding: 24px 32px;
        border-bottom-left-radius: 13px;
        border-top-left-radius: 13px;

        .logo-section {
            padding-right: 30px;
            padding-left: 0;
            img {
                max-width: 100%;
                display: block;
            }
        }
        .compnay-content {
            padding-left: 30px;
            padding-right: 0;
            border-left: 1px solid rgba(241, 245, 249, 0.12);
            font-size: 12px;
            color: var(--ion-white);

            input {
                outline: none;
                width: 100%;
                margin-bottom: 5px;
                background: none;
                border: 1px solid var(--ion-white);
                height: 30px;
                padding-left: 5px;
                padding-right: 5px;
                color: var(--ion-white);
            }

            textarea {
                font-size: 12px;
                color: var(--ion-white);
                outline: none;
                border-radius: 4px;
                resize: none;
                border-color: #64748b;
                width: 100%;
                height: 120px;
                background: none;
            }
        }
    }
}
.client-details {
    margin-top: 20px;
    color: var(--ion-font-color);
    .client-name {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 11px;
        max-width: 300px;
        input {
            border: 1px solid #64748b;
            border-radius: 4px;
            outline: none;
        }
    }
    .client-address,
    .client-email,
    .client-contact {
        font-size: 13px;
        max-width: 260px;
        textarea {
            width: 260px;
            border: 1px solid #64748b;
            border-radius: 4px;
            padding-top: 5px;
            padding-bottom: 5px;
            resize: none;
            outline: none;
        }
    }
}

.services-pdf {
    margin-top: 30px;
    margin-bottom: 64px;
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        border-bottom: 1px solid rgb(226, 232, 240);

        &:last-child {
            border-bottom: 0;
        }

        li {
            padding-top: 16px;
            padding-bottom: 16px;

            &:not(:first-child) {
                text-align: right;
            }
        }
        &.searvice-head {
            color: rgb(100, 116, 139);
            font-size: 14px;
            font-weight: 500;
        }
        &.searvice-body {
            font-size: 15px;
            color: var(--ion-font-color);
            font-weight: 500;
            li {
                text-align: left;
            }
        }
        .color-black {
            color: var(--ion-font-color);
        }
        .fz-16 {
            font-size: 16px !important;
        }
        .fz-20 {
            font-size: 20px !important;
            color: var(--ion-font-color);
            font-weight: bold;
        }
    }
}

.invoice-footer {
    textarea {
        font-size: 14px;
        color: var(--ion-font-color);
        font-weight: 500;
        width: 410px;
        height: 100px;
        outline: none;
        resize: none;
        border: 1px solid #64748b;
        border-radius: 4px;
        margin-bottom: 10px;
    }
    input {
        width: 410px;
        margin-bottom: 6px;
    }
    p {
        font-size: 14px;
        color: var(--ion-font-color);
        font-weight: 500;
    }
    .image-section {
        width: 120px;
        img {
            width: calc(100% - 10px);
            display: block;
        }
    }
    .content-section {
        width: calc(100% - 50px);
        p {
            margin: 0;
            font-size: 12px;
            color: var(--ion-dark-color);
        }
        textarea {
            width: 100%;
        }
    }
}

.mat-simple-snackbar {
    justify-content: center;
}

.budget-page {
    .mat-form-field-invalid {
        .mat-form-field-flex {
            border-color: red !important;
        }
    }
}

.circle-section {
    display: flex !important;
    align-items: center;
    padding-left: 6px !important;
    padding-right: 6px !important;
    flex-wrap: wrap;
    width: 199px;
    span {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: inline-block;
        margin: 0 3px 5px;
    }
}

.link {
    color: var(--ion-color-primary);
    --color: var(--ion-color-primary);
}

.cdk-overlay-pane {
    .mat-simple-snackbar {
        justify-content: center;
    }
}

.popup_header {
    padding: 10px 20px;
    background: var(--ion-color-primary);

    &.remove_padding {
        width: calc(100% + 48px);
        margin-left: -24px;
        margin-top: -24px;
    }

    p {
        margin: 0;
        color: var(--ion-white);
        font-weight: 500;
        font-size: 16px;
    }
    [mat-dialog-close] {
        color: var(--ion-white);
    }
}

.cdk-overlay-pane {
    &.stepper_full_popup {
        max-width: 100% !important;
        height: 100% !important;
        mat-dialog-container {
            padding-bottom: 0 !important;
        }
        .mat-dialog-container {
            border-radius: 0 !important;
        }
    }
}

.color-white {
    color: var(--ion-white) !important;
}
.text-decoration-underline {
    text-decoration: underline !important;
}

/* .cdk-global-overlay-wrapper {
    overflow: auto;
    pointer-events: auto; // needed for chrome but prevents backdrop click close
} */

.width-auto {
    width: auto !important;
}
.stepper_full_popup {
    mat-dialog-container {
        padding-left: 0 !important;
        padding-top: 0;
        padding-right: 0 !important;
    }
}
.mat-stepper-horizontal,
.mat-stepper-vertical {
    background: none;
}
