// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #4f46e5;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /* Custome Color */
  --ion-color-red: rgb(239, 68, 68);
  --ion-color-completed: rgb(34, 197, 94);
  --ion-color-dark-base: #191933;
  --ion-color-font-light: #b8bec7;
  --ion-color-body: #f8f8f8;
  --ion-white: #fff;
  --ion-color-blue-magenta: #4d4d71;
  --ion-color-blue-light: #4f46e5;
  /* --ion-color-blue-light: #f59e09; */
  --ion-color-primary: var(--ion-color-blue-light);
  --ion-white-rgb: 255, 255, 255;
  --ion-body-color: #f1f5f9;
  --ion-border-color: #e2e8f0;
  --ion-dark-color: #94a3b8;
  --ion-font-color: rgb(30, 41, 59);
  --ion-menu-background: rgb(15, 23, 42);
  --ion-border-input-color: #cbd5e1;

  --tab-border: #e6e6e6;
  --search-border: #f1f1f1;
  --menu-border: #e6eaf5;
}

.ion-color-dark-base {
  --ion-color-base: var(--ion-color-dark-base);
  --checkmark-color: var(--ion-white);
}
.ion-color-blue-magenta {
  --ion-color-base: var(--ion-color-blue-magenta);
}

.ion-color-blue-light {
  --ion-color-base: var(--ion-color-blue-light);
}
ion-chip.ion-color-blue-magenta {
  background: var(--ion-color-blue-magenta);
  color: var(--ion-white);
}

.ion-color-red {
  --ion-color-base: var(--ion-color-red);
}
.ion-color-completed {
  --ion-color-base: var(--ion-color-completed);
}
